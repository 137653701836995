import React, { useState, useEffect, useCallback } from 'react';
import './StaffList.css';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import RolNotification from "../../components/Notifications/RolNotification";
import { faEdit, faTrashAlt, faUserPlus, faEye, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import SlideModal from '../../components/SlideModal';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

const StaffList = ({ handleLogout }) => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRolOpen, setIsRolOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const hasPermission = (permission) => {
        const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
        return permissions.includes(permission);
    };

    const toggleModal = async (item) => {
        if (!isModalOpen) {
            try {
                const response = await apiClient.get(`/admin/staff/${item.code}`);
                const staff = {
                    general: item,
                    alternative: response.data,
                };
                setSelectedItem(staff);
                setIsModalOpen(true);
            } catch (error) {
                setIsErrorVisible(true);
                if (error.response && error.response.status === 401) {
                    setIsInfoVisible(true);
                }
            } finally {
                setIsSuccessVisible(true);
            }
        } else {
            setIsModalOpen(false);
        }
    };

    const fetchAllData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/admin/staff?page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(response.data.total);
            } else {
                setData([response.data.results]);
                setTotalItems(1);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const handleSearch = useCallback(async (page = 1) => {
        try {
            // Construir la URL con los query params
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            const response = await apiClient.get(`/admin/staff?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results); // Si es un array, lo dejamos tal cual
            } else {
                setData([response.data.results]); // Si es un objeto, lo encapsulamos en un array
            }
            setTotalItems(response.data.total);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [search]);
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const handleDelete = async (code) => {
        try {
            const url = `/admin/staff/${code}`;
            await apiClient.delete(url);
            handleRefresh();
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    };

    const handleRoleClick = (user) => {
        setSelectedUser(user);
        setIsRolOpen(true);
    };
    const handleRoleUpdate = () => {
        fetchAllData(currentPage); 
    };


    const columns = [
        { title: "Código", key: "code" },
        { title: "Nombre", key: "name" },
        { title: "Cédula", key: "cif" },
        { title: "Distribuidor", key: "distribuitor" },
        { title: "Rol", key: "rol" },
        { title: "Acciones", key: "acciones" },
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.code}</td>
            <td>{item.name}</td>
            <td>{item.cif}</td>
            <td>{item.distributorCode}</td>
            <td
                onClick={() => handleRoleClick(item)}
                className="role-cell"
            >
                {item.role}
            </td>
            <td>
                <div className="button-container">
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    {hasPermission('MANAGEMENT_USER_UPDATE') && (
                        <button
                            data-tooltip-id="edit-tooltip"
                            className="icon-button edit-button"
                            data-tooltip-content="Editar"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/StaffForm/${item.code}`);
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    )}
                    <Tooltip id="delete-tooltip" className="custom-tooltip" />
                    {hasPermission('MANAGEMENT_USER_DELETE') && (
                        <button
                            data-tooltip-id="delete-tooltip"
                            data-tooltip-content="Eliminar"
                            className="icon-button delete-button"
                            onClick={() => handleDelete(item.code)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    )}
                    {hasPermission('MANAGEMENT_USER_VIEW') && (
                        <button
                            className="icon-button company-button"
                            onClick={() => toggleModal(item)}
                            data-tooltip-id="contract-tooltip"
                            data-tooltip-content="Detalle"
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                    )}
                </div>
            </td>
        </>
    );


    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Personal' />
            <div className="home-content">
                <Section>
                    <div className="filter-form">
                        <div className="basic-info-form-group">
                            <input
                                type="text"
                                id="customer"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Personal"
                            />
                        </div>
                        <button className="search-button-customer" onClick={handleFresh}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            Buscar
                        </button>

                    </div>
                    <div className="button-add">
                        {hasPermission('MANAGEMENT_USER_CREATE') && (
                            <button
                                className="basic-custom-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/StaffForm');
                                }}
                            >
                                <FontAwesomeIcon className="basic-shortcut-icon" icon={faUserPlus} />
                                Crear Nuevo Personal
                            </button>
                        )}
                    </div>
                </Section>

                <Table
                    title="Lista de Personal"
                    rows={data}
                    columns={columns}
                    icon={faUsers}
                    renderRow={renderRow}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                    onRefresh={handleRefresh}
                    loading={loading}
                />
                <SuccessNotification
                    message={"Se ha cargado correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message="Ups! Ocurrio un Problema"
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />
                {isRolOpen && (
                    <RolNotification
                        message="Seleccione el nuevo Rol"
                        isVisible={isRolOpen}
                        onClose={() => setIsRolOpen(false)}
                        user={selectedUser}
                        onRoleUpdate={handleRoleUpdate}
                    />
                )}
                <SlideModal isOpen={isModalOpen} onClose={toggleModal} title="Detalles del Usuario">
                    <div className="section-container-form">
                        <h4 className="section-title-form">CÓDIGO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.code}</label>
                        </div>

                        <h4 className="section-title-form">RÁZON SOCIAL</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.name}</label>
                        </div>

                        <h4 className="section-title-form">CÉDULA</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.cif}</label>
                        </div>

                        <h4 className="section-title-form">ROL</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.role}</label>
                        </div>

                        <h4 className="section-title-form">DISTRIBUIDOR</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.distributorCode}</label>
                        </div>

                        <h4 className="section-title-form">TELÉFONO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.alternative.percelular || selectedItem?.alternative.pertelf}</label>
                        </div>

                        <h4 className="section-title-form">EMAIL</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.alternative.email}</label>
                        </div>
                    </div>
                </SlideModal>
            </div>
        </div>
    );
};

export default StaffList;
