import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import "./ContractNew.css";
import Header from "../../components/Header";
import Section from "../../components/Section";
import CustomerModal from "../../components/CustomerModal";
import NotificationContract from '../../components/Notifications/NotificationContract';
import SuccessProcess from '../../components/Notifications/SuccessProcess';
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRotate,
  faCircleArrowLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import apiClient from "../../axios";

const ContractNew = ({ handleLogout }) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const location = useLocation();
  const [distributors, setDistributors] = useState([]);
  const [typeContract, setTypeContract] = useState([]);
  const [proxPago, setProxPago] = useState(null);
  // const [codigo, setCodigo] = useState("");
  const [numSerie, setSerie] = useState(location.state?.contract[0] ? String(location.state?.contract[0]?.numCont) : '' || '');
  const [cliente, setCliente] = useState(location.state?.contract[0] ? location.state?.contract[0].cliente : location.state?.cif || '');
  const [observacion, setObservacion] = useState("");
  const [contEmpre, setContEmpre] = useState("");
  const [servidor, setServidor] = useState("");
  const [tipocontra, setTipoProducto] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [numSer, setNumSer] = useState(1);
  const [numCli, setNumCli] = useState(1);
  const [aNumSer, setAnumSer] = useState(0);
  const [aNumCli, setAnumCli] = useState(0);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [setClients] = useState([]);
  const [errors, setErrors] = useState({});
  const [contractNumber, setContractNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [isSetSuccessVisible, setIsSetSuccessVisible] = useState();
  const [reg1, setReg1] = useState();
  const role = localStorage.getItem('role');
  const distributorToken = localStorage.getItem('distributor');
  const [distribuidor, setCanal] = useState(role !== 'SUPER_ADMIN' && role !== 'ADMIN' ? distributorToken : distributorToken);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    setProxPago(formattedDate);
  };

  useEffect(() => {
    apiClient
      .get("/clients")
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los clientes:", error);
      });
  }, [setClients]); 


  useEffect(() => {
    // Solo ejecuta fetchDistributors si el role es ADMIN o SUPER_ADMIN
    if (role === 'ADMIN' || role === 'SUPER_ADMIN') {
      const fetchDistributors = async () => {
        try {
          const response = await apiClient.get("/distributors");
          setDistributors(response.data);
        } catch (error) {
          console.error("Error fetching distributors:", error);
        }
      };

      fetchDistributors();
    }
  }, [role]);

  useEffect(() => {
    const fetchTipoContra = async () => {
      try {
        const response = await apiClient.get("/contract_type");
        setTypeContract(response.data);
      } catch (error) {
        console.error("Error fetching contract:", error);
      }
    };

    fetchTipoContra();
  }, []);

  const handleTipoContratoChange = (e) => {
    const selectedContractCode = e.target.value;
    setTipoContrato(selectedContractCode);
    const selectedContract = typeContract.find(
      (contract) => contract.code === selectedContractCode
    );
    if (selectedContract && selectedContract.productTypes) {
      const products = selectedContract.productTypes.filter(
        (product) => product.isPack === 1
      );
      setFilteredProducts(products);
    } else {
      setFilteredProducts([]); 
    }
  };

  const validateFields = () => {
    let validationErrors = {};
    if (!numSerie) validationErrors.numSerie = "El Campo es Obligatorio";
    if (!cliente) validationErrors.cliente = "El Campo es Obligatorio";
    if (!distribuidor)
      validationErrors.distribuidor = "El Campo es Obligatorio";
    if (!servidor) validationErrors.servidor = "El Campo es Obligatorio";
    if (!tipocontra) validationErrors.tipocontra = "El Campo es Obligatorio";
    if (!contEmpre) validationErrors.contEmpre = "El Campo es Obligatorio";
    if (!tipoContrato)
      validationErrors.tipoContrato = "El Campo es Obligatorio";
    if (!proxPago) validationErrors.proxPago = "El Campo es Obligatorio";
    if (!observacion) validationErrors.observacion = "El Campo es Obligatorio";
    return validationErrors;
  };

  const handleCounterChange = (counterType, operation) => {
    if (counterType === "ser") {
      setNumSer((prev) =>
        operation === "increment" ? prev + 1 : prev > 0 ? prev - 1 : 0
      );
    } else if (counterType === "cli") {
      setNumCli((prev) =>
        operation === "increment" ? prev + 1 : prev > 0 ? prev - 1 : 0
      );
    } else if (counterType === "aser") {
      setAnumSer((prev) =>
        operation === "increment" ? prev + 1 : prev > 0 ? prev - 1 : 0
      );
    } else if (counterType === "acli") {
      setAnumCli((prev) =>
        operation === "increment" ? prev + 1 : prev > 0 ? prev - 1 : 0
      );
    }
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectClient = (clientName) => {
    setCliente(clientName);
  };

  const handleCloseSuccess = () => {
    setSuccessVisible(false);
    setIsSetSuccessVisible(true);
  };

  const isHandleCloseSuccess = () => {
    setIsSetSuccessVisible(false);
    navigate(-1);
  };

  const handleSave = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const payload = {
      numSerie,
      cliente,
      distribuidor,
      servidor,
      tipocontra,
      contEmpre,
      observacion,
      tipoContrato,
      numSer,
      numCli,
      aNumSer,
      aNumCli,
      proxPago,
    };
    const payloadUpdate = {
      distribuidor,
      servidor,
      observacion,
      tipocontra,
      tipoContrato,
      numSer,
      contEmpre,
      numCli,
      aNumSer,
      aNumCli,
      proxPago,
    };

    try {
      if (productId) {
        // Modo edición: usar PATCH
        await apiClient.patch(`/products/${productId}`, payloadUpdate);
      } else {
        // Modo creación: usar POST 
        const response = await apiClient.post("/contracts", payload);
        const newContractNumber = response.data?.data?.numCont;
        setContractNumber(newContractNumber);
        if (response.status === 201) {
          const register = response.data?.data?.reg1 + ' | ' + response.data?.data?.reg2;
          setReg1(register);
          try {
            const response = await apiClient.get(`/module_pack?productType=${tipocontra}`);
            const modulesList = response.data;
            const payloads = modulesList.map(module => ({
              numContId: newContractNumber,
              modulo: module.moduleCode,
              canal: distribuidor,
              num_licencias: module.numLicencias,
              is_pay: module.isPay === 1,
              max_count: module.maxCount,
              fechaFin: proxPago
            }));
            await apiClient.post('/modules/bulk', payloads);
            if (response.status === 200) {
              setSuccessVisible(true);
            }
            else {
              setIsErrorVisible(true);
            }
          } catch (error) {
            setIsErrorVisible(true);
            console.error("Error al enviar los módulos:", error);
          }
        }
      }
      setIsErrorVisible(false);
      setErrors({});
    } catch (error) {
      setIsErrorVisible(true);
      setIsSuccessVisible(false);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 4000);
      if (error.response && error.response.status === 401) {
        setIsLoginVisible(true);
      }
    }
  };
  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title="Contratos" />
      <Section>
        <div className="button-return-container">
          <FontAwesomeIcon
            className="basic-shortcut-icon"
            style={{ cursor: "pointer" }}
            icon={faCircleArrowLeft}
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          />
        </div>
      </Section>
      <div className="moduleNew-form-container">
        <h3 className="basic-info-form-title">Información del Contrato</h3>
        <div className="basic-info-form-grid">
          <div className="basic-info-form-group">
            <label style={{ color: errors.servidor ? "red" : "inherit" }}>
              Servidor
            </label>
            <input
              type="text"
              placeholder="Servidor"
              value={servidor}
              onChange={(e) => setServidor(e.target.value)}
            />
            {errors.servidor && (
              <p className="error-message">{errors.servidor}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.numSerie ? "red" : "inherit" }}>
              Nro. Identificador
            </label>
            <input
              type="text"
              placeholder="Nro. Identificador"
              value={numSerie}
              onChange={(e) => setSerie(e.target.value)}
            />
            {errors.numSerie && (
              <p className="error-message">{errors.numSerie}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.cliente ? "red" : "inherit" }}>
              Cliente
            </label>
            <input
              type="text"
              placeholder="Cliente"
              value={cliente}
              readOnly
            />
            <CustomerModal
              isOpen={isModalOpen}
              closeModal={closeModal}
              selectClient={selectClient}
            />
            {errors.cliente && (
              <p className="error-message">{errors.cliente}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.distribuidor ? "red" : "inherit" }}>
              Distribuidor
            </label>
            {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
              <select
                value={distribuidor}
                onChange={(e) => setCanal(e.target.value)}
              >
                <option value="">Seleccione un Distribuidor</option>
                {distributors.map((distributor) => (
                  <option key={distributor.code} value={distributor.code}>
                    {distributor.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                value={distribuidor}
                readOnly
              />
            )}
            {errors.distribuidor && (
              <p className="error-message">{errors.distribuidor}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.tipoContrato ? "red" : "inherit" }}>
              Tipo Contrato
            </label>
            <select
              value={tipoContrato}
              onChange={handleTipoContratoChange}
            >
              <option value="">Seleccione un Tipo Contrato</option>
              {typeContract.map((contract) => (
                <option key={contract.code} value={contract.code}>
                  {contract.description}
                </option>
              ))}
            </select>
            {errors.tipoContrato && (
              <p className="error-message">{errors.tipoContrato}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.tipocontra ? "red" : "inherit" }}>
              Producto
            </label>
            <select
              value={tipocontra}
              onChange={(e) => setTipoProducto(e.target.value)}
            >
              <option value="">Seleccione un Tipo Producto</option>
              {filteredProducts.map((product) => (
                <option key={product.code} value={product.code}>
                  {product.description}
                </option>
              ))}
            </select>
            {errors.tipocontra && (
              <p className="error-message">{errors.tipocontra}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.observacion ? "red" : "inherit" }}>
              Observación
            </label>
            <input
              type="text"
              placeholder="Observación"
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
            />
            {errors.observacion && (
              <p className="error-message">{errors.observacion}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.contEmpre ? "red" : "inherit" }}>
              Descripción
            </label>
            <input
              type="text"
              placeholder="Observación"
              value={contEmpre}
              onChange={(e) => setContEmpre(e.target.value)}
            />
            {errors.contEmpre && (
              <p className="error-message">{errors.contEmpre}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.proxPago ? "red" : "inherit" }}>
              Caduca{" "}
            </label>
            <div className="basic-info-date-picker">
              <DatePicker
                selected={proxPago ? new Date(proxPago) : null}
                onChange={handleDateChange}
                showTimeSelect
                locale={es}
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText="Selecciona la Fecha"
                className="custom-date-picker"
              />

              {errors.proxPago && (
                <p className="error-message">{errors.proxPago}</p>
              )}
            </div>
          </div>
        </div>
        <hr className="divider" />
        <h3 className="basic-info-form-title">Número de Licencias</h3>
        <div className="basic-info-form-grid">
          <div className="basic-info-form-group2">
            <label className="basic-title">Módulo Comercial</label>
            <div className="counter-group">
              <div className="counter">
                <p className="basic-subtittle">Servidores</p>
                <div className="custom-counter">
                  <button
                    onClick={() => handleCounterChange("ser", "decrement")}
                  >
                    -
                  </button>
                  <span>{numSer}</span>
                  <button
                    onClick={() => handleCounterChange("ser", "increment")}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="counter">
                <p className="basic-subtittle">Clientes</p>
                <div className="custom-counter">
                  <button
                    onClick={() => handleCounterChange("cli", "decrement")}
                  >
                    -
                  </button>
                  <span>{numCli}</span>
                  <button
                    onClick={() => handleCounterChange("cli", "increment")}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basic-form-footer">
          <button
            className="basic-custom-button"
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
            Cancelar
          </button>
          <button className="basic-custom-button" onClick={handleSave}>
            <FontAwesomeIcon
              icon={productId ? faRotate : faSave}
              className="basic-shortcut-icon"
            />
            {productId ? "Actualizar" : "Guardar"}
          </button>
        </div>
      </div>
      <SuccessNotification
        message={
          productId
            ? `El Contrato se ha Actualizado Correctamente.`
            : `El Contrato se ha Creado Correctamente${contractNumber
              ? ` con Número de Contrato ${contractNumber}`
              : ""
            }.`
        }
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
        position="center"
      />
      <ErrorNotification
        message="Hubo un problema al Crear el Contrato. Inténtalo de nuevo."
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isLoginVisible}
        onClose={() => setIsLoginVisible(false)}
        onLogout={handleLogout}
      />
      <SuccessProcess
        message={`El Contrato se ha creado correctamente${contractNumber ? ` con Número de Contrato ${contractNumber}` : ""}.`}
        isVisible={successVisible}
        onClose={handleCloseSuccess}
        redirectOnOk = {false}
      />
      <NotificationContract
        message={`Los números de registros son:`}
        reg1={reg1}  // Pasando reg1 como prop
        isVisible={isSetSuccessVisible}
        onClose={isHandleCloseSuccess}
      />
    </div>
  );
};

export default ContractNew;
