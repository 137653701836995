import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useCallback } from 'react';
import "./PurchaseForm.css";
import Header from "../../components/Header";
import Section from "../../components/Section";
import TablePurchase from '../../components/TablePurchase';
import OrderModal from '../../components/OrderModal';
import CustomerModal from "../../components/CustomerModal";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faRotate,
  faCircleArrowLeft,
  faXmark,
  faCubes
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const PurchaseForm = ({ handleLogout }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { orderId } = useParams();
  const location = useLocation();
  const [typeContract, setTypeContract] = useState([]);
  const [numSerie, setSerie] = useState(location.state?.contract[0] ? String(location.state?.contract[0]?.numCont) : '' || '');
  const [cliente, setCliente] = useState(location.state?.contract[0] ? location.state?.contract[0].cliente : location.state?.cif || '');
  const [observacion, setObservacion] = useState("");
  const [contEmpre, setContEmpre] = useState("");
  const [servidor, setServidor] = useState("");
  const [tipocontra, setTipoContra] = useState("");
  const [contractTypeId, setContractTypeId] = useState(0);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isModalCustomerOpen = false;
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [setClients] = useState([]);
  const [errors, setErrors] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [selectedModules, setSelectedModules] = useState([]);
  const [numOrder, setNumOrder] = useState('');
  const [orderTotal, setOrderTotal] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [productTypeId, setProductTypeId] = useState(0);
  const [subscriptionCounts, setSubscriptionCounts] = useState({});
  const [selectedContractProducts, setSelectedContractProducts] = useState([]);
  const [mergedRows, setMergedRows] = useState([]);

  useEffect(() => {
    apiClient
      .get("/clients")
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los clientes:", error);
      });
  }, [setClients]); // Add 'setClients' to the dependency array

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectClient = (clientName) => {
    setCliente(clientName);
  };

  const fetchAllData = useCallback(async (page = 1) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/module_pack?productType=${tipocontra}`);
      if (Array.isArray(response.data)) {
        setData(response.data);
        setTotalItems(response.data.length);
      } else {
        if (response.data.results.contractType === tipocontra || response.data.contractType === null) {
          setData([response.data.results]);
          setTotalItems(response.data.total);
        } else {
          setData([]);
          setTotalItems(1);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsLoginVisible(true);
      }
    } finally {
      setLoading(false);
    }
  }, [tipocontra]);


  useEffect(() => {
    fetchAllData();
  }, [fetchAllData, tipocontra]);

  useEffect(() => {
    const fetchTipoContra = async () => {
      try {
        const response = await apiClient.get("/contract_type");
        setTypeContract(response.data);
      } catch (error) {
        console.error("Error fetching contract types:", error);
      }
    };
    fetchTipoContra();
  }, []);

  const handleTipoContratoChange = (e) => {
    const selectedId = e.target.value;
    setContractTypeId(selectedId);
    const selectedContract = typeContract.find(contract => contract.id === parseInt(selectedId));
    if (selectedContract && selectedContract.productTypes.length > 0) {
      const filteredProducts = selectedContract.productTypes.filter(product => product.isPack === 1);
      setSelectedContractProducts(filteredProducts);
    } else {
      setSelectedContractProducts([]);
    }
  };

  const handleProductChange = (e) => {
    const selectedProductCode = e.target.value;
    setTipoContra(selectedProductCode);
    const selectedProduct = selectedContractProducts.find(product => product.code === selectedProductCode);
    if (selectedProduct) {
      setProductTypeId(selectedProduct.id);
    } else {
      setProductTypeId('');
    }
  };

  const validateFields = () => {
    let validationErrors = {};
    if (!numSerie) validationErrors.numSerie = "El Campo es Obligatorio";
    if (!cliente) validationErrors.cliente = "El Campo es Obligatorio";
    if (!servidor) validationErrors.servidor = "El Campo es Obligatorio";
    if (!tipocontra) validationErrors.tipocontra = "El Campo es Obligatorio";
    if (!contEmpre) validationErrors.contEmpre = "El Campo es Obligatorio";
    if (!contractTypeId)
      validationErrors.contractTypeId = "El Campo es Obligatorio";
    if (!observacion) validationErrors.observacion = "El Campo es Obligatorio";
    return validationErrors;
  };

  const columns = [
    { title: "Nombre", key: "description" },
    { title: "Descripción", key: "description" },
    { title: "Precio Modulo", key: "precioCompra" },
    { title: "Suscripciones Extras", key: "extraSus" },
    { title: "Total", key: "precioSus" },
  ];

  const handleIncrement = (index) => {
    setSubscriptionCounts((prevCounts) => ({
      ...prevCounts,
      [index]: (prevCounts[index] || 0) + 1,
    }));
  };

  const handleDecrement = (index) => {
    setSubscriptionCounts((prevCounts) => {
      const newCount = (prevCounts[index] || 0) - 1;
      return {
        ...prevCounts,
        [index]: newCount < 0 ? 0 : newCount,
      };
    });
  };

  const calculateSubtotal = () => {
    return mergedRows.reduce((acc, item, index) => {
      const count = subscriptionCounts[index] || item.count || 0;
      const total = count * item.subscriptionPrice + item.purchasePrice;
      return acc + total;
    }, 0);
  };



   const renderRow = (item, index) => {
    const count = subscriptionCounts[index] || item.count || 0;
    const total = count * item.subscriptionPrice + item.purchasePrice;

    return (
      <>
        <td>{item.moduleName}</td>
        <td>{item.description}</td>
        <td className="align-right">${item.purchasePrice.toFixed(2)}</td>
        <td className="align-right">
          {item.subscriptionPrice !== 0 ? (
            <>
              <div className="sale-counter">
                <button onClick={() => handleDecrement(index)}>-</button>
                <span>{count} </span>
                <button onClick={() => handleIncrement(index)}>+</button>
              </div>
              <div className="align-center ">${item.subscriptionPrice.toFixed(2)}</div>
            </>
          ) : (
            <div className="align-center ">-</div>
          )}
        </td>
        <td className="align-right">${total.toFixed(2)}</td>
      </>
    );


  };


  const handleSave = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const purchasedModules = selectedModules
      .filter(item => item.count > 0)
      .map(item => ({
        productId: item.moduleId,
        templateId:item.id,
        name: item.moduleName,
        extraSubscriptions: item.count
      }));

    const payload = {
      contractData: {
        numSerie,
        cliente,
        servidor,
        productTypeId,
        contEmpre,
        observacion
      },
      purchasedModules
    };

    try {
      console.log(payload);
      const response = await apiClient.post("/orders", payload);
      if (response.status === 201) {
        setIsSuccessVisible(true);
        const orderNumber = response.data?.order.id;
        setNumOrder(orderNumber);
        const totalOrder = response.data?.order.total;
        setOrderTotal(totalOrder);
        setOrderData(response.data?.order);
        navigate(`/PurchaseSummary/${orderNumber}`);
        setTimeout(() => {
          setIsSuccessVisible(false);
        }, 4000);
      }
    } catch (error) {
      setIsErrorVisible(true);
      setIsSuccessVisible(false);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 4000);
      if (error.response && error.response.status === 401) {
        setIsLoginVisible(true);
      }
    }
  };


  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title="Contratos" />
      <Section>
        <div className="button-return-container">
          <FontAwesomeIcon
            className="basic-shortcut-icon"
            style={{ cursor: "pointer" }}
            icon={faCircleArrowLeft}
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          />
        </div>
      </Section>
      <div className="moduleNew-form-container">
        <h3 className="basic-info-form-title">Información de la Orden</h3>
        <div className="basic-info-form-grid">
          <div className="basic-info-container-group">
            <h3 className="modal-title ">Datos del Contrato</h3>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.cliente ? "red" : "inherit" }}>
                Cliente
              </label>
              <input
                type="text"
                placeholder="Cliente"
                value={cliente}
                readOnly
              />
              <CustomerModal
                isOpen={isModalCustomerOpen}
                closeModal={closeModal}
                selectClient={selectClient}
              />
              {errors.cliente && (
                <p className="error-message">{errors.cliente}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.servidor ? "red" : "inherit" }}>
                Servidor
              </label>
              <input
                type="text"
                placeholder="Servidor"
                value={servidor}
                onChange={(e) => setServidor(e.target.value)}
              />
              {errors.servidor && (
                <p style={{ fontSize: '9px', color: 'red' }}>{errors.servidor}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.numSerie ? "red" : "inherit" }}>
                Nro. Identificador
              </label>
              <input
                type="text"
                placeholder="Nro. Identificador"
                value={numSerie}
                onChange={(e) => setSerie(e.target.value)}
              />
              {errors.numSerie && (
                <p className="error-message">{errors.numSerie}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.contractTypeId ? "red" : "inherit" }}>
                Tipo Contrato
              </label>
              <select
                value={contractTypeId}
                onChange={handleTipoContratoChange}
              >
                <option value="">Seleccione un Tipo Contrato</option>
                {typeContract.map(contract => (
                  <option key={contract.id} value={contract.id}>
                    {contract.description}
                  </option>
                ))}
              </select>
              {errors.contractTypeId && (
                <p style={{ fontSize: '9px', color: 'red' }}>{errors.contractTypeId}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.tipocontra ? "red" : "inherit" }}>
                Producto
              </label>
              <select
                value={tipocontra}
                onChange={handleProductChange}
              >
                <option value="">Seleccione un Tipo Producto</option>
                {selectedContractProducts.map(product => (
                  <option key={product.code} value={product.code}>
                    {product.description}
                  </option>
                ))}
              </select>
              {errors.tipocontra && (
                <p style={{ fontSize: '9px', color: 'red' }}>{errors.tipocontra}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.observacion ? "red" : "inherit" }}>
                Observación
              </label>
              <input
                type="text"
                placeholder="Observación"
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
              />
              {errors.observacion && (
                <p style={{ fontSize: '9px', color: 'red' }}>{errors.observacion}</p>
              )}
            </div>
            <div className="basic-info-form-group4">
              <label style={{ color: errors.contEmpre ? "red" : "inherit" }}>
                Descripción
              </label>
              <input
                type="text"
                placeholder="Descripción"
                value={contEmpre}
                onChange={(e) => setContEmpre(e.target.value)}
              />
              {errors.contEmpre && (
                <p style={{ fontSize: '9px', color: 'red' }}>{errors.contEmpre}</p>
              )}
            </div>
          </div>
          <div className="basic-info-container-group">
            <h3 className="modal-title ">Módulos</h3>
            <p>El contrato contine los siguientes Módulos</p>
            <TablePurchase
              title="Lista de Módulos"
              rows={data}
              columns={columns}
              icon={faCubes}
              renderRow={renderRow}
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={setCurrentPage}
              onRefresh={fetchAllData}
              loading={loading}
              selectedModules={selectedModules}
              setSelectedModules={setSelectedModules}
              onMergeRows={setMergedRows} 
            />
          </div>
        </div>
        <div className="basic-form-footer">
          <div className="basic-footer-container-group ">
            <table style={{ borderCollapse: 'collapse', width: '30%' }}>
              <tbody>
                <tr>
                  <td style={{ padding: '5px', textAlign: 'left' }}>
                    <label>Subtotal:</label>
                  </td>
                  <td style={{ padding: '5px', textAlign: 'right' }}>
                    <span>${calculateSubtotal().toFixed(2)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="basic-form-footer">
          <button
            className="basic-custom-button"
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
            Cancelar
          </button>
          <button className="basic-custom-button" onClick={handleSave}>
            <FontAwesomeIcon
              icon={orderId ? faRotate : faCartShopping}
              className="basic-shortcut-icon"
            />
            {orderId ? "Actualizar" : "ir a Comprar"}
          </button>
        </div>
      </div>
      <SuccessNotification
        message={"Orden Creada"}
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
        position="center"
      />
      <ErrorNotification
        message="Hubo un problema al Crear el Contrato. Inténtalo de nuevo."
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isLoginVisible}
        onClose={() => setIsLoginVisible(false)}
      />
      {isModalOpen && (
        <div className="modal-overlay-edit">
          <div className="modal-content-edit">
            <OrderModal
              data={orderData}
              message={`Se creo la orden con el numero ${numOrder} y un total de $${orderTotal}`}
              id={numOrder}
              isVisible={isModalOpen}
              onClose={() => closeModal()}
            />

          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseForm;
